import React, { Fragment, useEffect, useState } from 'react';
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  FormControl,
  InputLabel
} from '@mui/material';
import Card from '@components/Card';
import { useTranslation } from 'react-i18next';

const PreparationConfiguration = ({
  getNewJourneys,
  journeyIndex,
  data,
  setData,
  preparations,
  idPreparation,
  cyclePreparations
}) => {
  const { t } = useTranslation();

  // Validar si journey existe
  const journey = data?.[journeyIndex] || {};
  const [lJourney, setLJourney] = useState({ ...journey });

  // Maneja la renderización del valor en el Select
  const manageRenderValue = (selected) => {
    if (!preparations) return '';
    const preparation = preparations.find((e) => e.idPreparation === selected);
    return preparation?.preparationName || t('CONFIG_JOURNEYS.PREPARATION.DEFAULT');
  };

  // Sincronizar lJourney cuando cambien data o idPreparation
  useEffect(() => {
    setLJourney((prev) => ({
      ...prev,
      ...data?.[journeyIndex],
      idPreparation: idPreparation || prev.idPreparation
    }));
  }, [data, journeyIndex, idPreparation]);

  // Manejar cambios en el Select
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedJourney = { ...lJourney, [name]: value };
    setLJourney(updatedJourney);

    // Actualizar datos en el componente padre
    const newJourneys = getNewJourneys(value, name, journeyIndex);
    setData(newJourneys);
  };

  const translatePreparation = (typeJourney) => {    
        
    if (!cyclePreparations || !cyclePreparations[journeyIndex] || typeJourney == 'undefined') {
      return 0; // Valor por defecto si los datos no están disponibles
    }
  
    const currentCycle = cyclePreparations[journeyIndex];
  
    if (typeJourney == 1) {
      return currentCycle?.cycleTypeJourneyPIdPreparation ? currentCycle?.cycleTypeJourneyPIdPreparation : 0;
    } else if (typeJourney == 2) {      
      return currentCycle?.cycleTypeJourneyDIdPreparation ? currentCycle?.cycleTypeJourneyDIdPreparation : 0;
    } 
  
    return 0; // Valor por defecto para cualquier otro caso
  };

  return (
    <Fragment>
      <Typography
        textAlign="center"
        color="white"
        variant="subtitle1"
        sx={{
          bgcolor: 'grey.main',
          color: 'primary.main',
          borderRadius: 1,
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 'bold',
          py: 1
        }}
      >
        {t('CONFIG_JOURNEYS.PREPARATION.CONFIG').toUpperCase()}
      </Typography>
      <Card fullHeight>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel id="idPreparation">
              {t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
            </InputLabel>
            <Select
              labelId="idPreparation"
              label={t('CONFIG_JOURNEYS.PREPARATION.PREPARATION')}
              value={translatePreparation(lJourney?.idPreparation?.[journeyIndex] || 0) || 0}
              onChange={handleChange}
              name="idPreparation"
              renderValue={(selected) => manageRenderValue(selected)}
            >
              {preparations?.map((preparation) => (
                <MenuItem
                  value={preparation.idPreparation}
                  key={preparation.idPreparation}
                >
                  {preparation.idPreparation === 0
                    ? t(preparation.preparationName)
                    : preparation.preparationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Card>
    </Fragment>
  );
};

export default PreparationConfiguration;
