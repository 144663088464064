import React, { Fragment } from "react";
import Card from "@components/Card";
import { Grid, Box, CircularProgress, Typography, Checkbox } from "@mui/material";
import SectionCycleJourneys from "./SectionCycleJourneys";
import { useTranslation } from "react-i18next";

const SectionCycleJourneysTabs = (props) => {
  const {
    cycleJourneys,
    loadingJourneys,
    cycleTypeModeLive,
    journeyTypeModeLive,
    setJourneyTypeModeLive,
  } = props;
  const { t } = useTranslation();

  const handleChangeJourneyCheck = (e, index) => {
    const data = e.target.checked ? 1 : 2;

    // Actualizar el estado en TabConfiguration
    setJourneyTypeModeLive((prevState) =>
      prevState.map((j, i) => (i === index ? data : j))
    );
  };

  if (loadingJourneys) {
    return (
      <Grid
        container
        style={{ minHeight: "20vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Card
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption">
            {t("CYCLES.JOURNEYS.LOADING_JOURNEYS")}
          </Typography>
          <CircularProgress />
        </Card>
      </Grid>
    );
  }

  if (!cycleJourneys) {
    return (
      <Grid
        container
        style={{ minHeight: "10vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Card>
          <Typography variant="caption" color="primary">
            {t("CYCLES.JOURNEYS.SELECT_CYCLE_TYPE")}
          </Typography>
        </Card>
      </Grid>
    );
  }

  if (cycleJourneys.length === 0) {
    return (
      <Card fullHeight flex>
        <Fragment>
          <Typography variant="caption">
            {t("CYCLES.JOURNEYS.NO_JOURNEYS")}
          </Typography>
        </Fragment>
      </Card>
    );
  }

  return (
    <Box sx={{ width: "100%" }} my={2}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          variant="subtitle1"
          mb={2}
          mx={2}
          textAlign="left"
          borderRadius="4px"
          fontSize={14}
          fontWeight="bold"
          padding="7px 0 7px 0"
        >
          {t("CYCLES.JOURNEYS.JOURNEYS").toUpperCase()}
        </Typography>
        {cycleJourneys.length > 0 && cycleTypeModeLive == 3 && (
          <Typography
            variant="caption"
            mb={2}
            mr={1}
            textAlign="left"
            fontSize={12}
          >
            {t("CYCLES.JOURNEYS.SELECT_FACE")}
          </Typography>
        )}

        {cycleJourneys.length > 0 &&
          cycleTypeModeLive == 3 &&
          cycleJourneys.map((journey, i) => {
            let valueModeCodeShown = journeyTypeModeLive[i];

            return (
              <Checkbox
                key={`checkJourneyTypeMode-${i}`}
                sx={{ ml: 0, mb: 2, p: 0.5 }}
                size="verysmall"
                edge="start"
                id={`checkJourneyTypeMode-${i}`}
                name={`checkJourneyTypeMode-${i}`}
                value={valueModeCodeShown == 1 ? true : false}
                checked={valueModeCodeShown == 1 ? true : false}
                onChange={(e) => {
                  handleChangeJourneyCheck(e, i);
                }}
              />
            );
          })}
      </Box>
      <Grid container spacing={1} mt={1}>
        <SectionCycleJourneys {...props} />
      </Grid>
    </Box>
  );
};

export default SectionCycleJourneysTabs;
