import React, { useEffect, useState, useRef, useContext } from 'react';
import { useStyles } from '@styles/participants.style';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Checkbox,
  Button,
  Box,
  TextField,
  Typography
} from '@mui/material';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import getCurrentJourney from '@helpers/currentJourney';
import getNextJourney from '@helpers/nextJourney';
import serviceParticipantsTargets from '@services/serviceParticipantsTargets';
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants';
import Loading from '@components/Loading';
import Logo from '@assets/images/logoCampus.png';
import { snackActions } from '@helpers/snackbarUtils';
import serviceCycleJourneys from '@services/serviceCycleJourneys';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AuthContext } from '@context/auth/AuthContext';

const Targets = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    cycle: [cycle, setCycle],
    displaySaw: [displaySaw, setDisplaySaw]
  } = useOutletContext();
  const [displayPrep, setDisplayPrep] = useState('');
  const [nextTargets, setNextTargets] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [checkFree, setCheckFree] = useState(false);
  const [targetTexts, setTargetTexts] = useState({});
  const inputRef = useRef({});
  const [isFirstTimeSelection, setIsFirstTimeSelection] = useState(true);
  const [targetsOn, setTargetsOn] = useState(true);
  const { user } = useContext(AuthContext);
  const lang = sessionStorage.getItem('lang');
  const idLang = lang === 'es' ? 1 : lang === 'en' ? 2 : lang === 'it' ? 3 : 1;
  const [hasPreviousTargets, setHasPreviousTargets] = useState(false);
  const [idJourney, setIdJourney] = useState(null);
  const [journey, setJourney] = useState(getCurrentJourney(cycle));
  const currentJourneyIndex = cycle.cycleJourneys.indexOf(journey);
  const [isLastJourney, setIsLastJourney] = useState(currentJourneyIndex === cycle.cycleJourneys.length - 1);
  const [participantDetails, setParticipantDetails] = useState({});
  const [originalTargets, setOriginalTargets] = useState([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const isSER = journey.idCycle === null || journey.idCycle == 0;

  useEffect(() => {
      let currentJourneyIndex = cycle.cycleJourneys.indexOf(journey);
      setIsLastJourney(currentJourneyIndex === cycle.cycleJourneys.length - 1);
  }, [journey]);   

  useEffect(() => {
    console.log(`idParticipant: ${user.idParticipant}, idCycle: ${journey.idCycle}, isSER: ${isSER}, IdCycleJourney: ${journey.idCycleJourney}, CycleJourneyNumber: ${journey.cycleJourneyNumber}, isLastJourney: ${isLastJourney}, ActiveAssesment: ${journey.activeAssesment}, idPreparation: ${journey.idPreparation}, Multilínea en isFreeText: ${isLastJourney && journey.idCycle !== null}, CycleJourneyDateFrom: ${journey.cycleJourneyDateFrom}, CycleJourneyDateTo: ${journey.cycleJourneyDateTo}, 
      ¿Targets?: ${hasPreviousTargets}, 
      ¿Valoró?: ${!!(participantDetails.cycleJourneyParticipantRemarks && participantDetails.cycleJourneyParticipantRate)}`);
  }, [
    isLastJourney,
    journey.idCycle,
    journey.idCycleJourney,
    journey.activeAssesment,
    journey.idPreparation, // Añadido como dependencia
    user.idParticipant,
    journey.cycleJourneyNumber,
    journey.cycleJourneyDateFrom,
    journey.cycleJourneyDateTo,
    hasPreviousTargets,
    participantDetails.cycleJourneyParticipantRemarks,
    participantDetails.cycleJourneyParticipantRate
  ]); 
  
  const handleNext = async () => {
    if (!journey) {
        console.error('No journey found');
        return;
    }
  
    // Verificación específica para la última jornada
    if (isLastJourney) {
      if (journey.activeAssesment == 0) {
        snackActions.error(t('PARTICIPANTS.NO_ACTIVEASSESMENT'));
        return;
      } else if (journey.activeAssesment == 1) {
        navigate("/scoreJourney", { state: { journey, idJourney } });
        return;
      }
      console.warn('No changes allowed in targets on the last journey.');
      return;
    }
  
    // Jornada normal no última y ActiveAssesment = 1
    if (!isLastJourney && journey.idCycle != null && journey.activeAssesment == 1) {
      console.log("Intermediate normal journey with ActiveAssesment active.");
      if (haveTargetsChanged()) {
        const saved = await postTargets();
        if (!saved) {
          console.error('Targets were not saved');
          return;
        }
      }
      navigate("/scoreJourney", { state: { journey, idJourney } });
      return;
    }
  
    // Navegación alternativa si idPreparation = 1
    if (journey.idPreparation === 1) {
      navigate("/activeJourney", { state: { journey, idJourney } });
    }
  };

  useEffect(() => {
    //NOTA IMPORTANTE: TENEMOS QUE HACER ESTO PORQUE SEGÚN POR DONDE ENTRA DEVUELVE UNA ESTRUCTURA DE DATOS U OTRA Y CAMBIAR TODA LA ESTRUCTURA EN BACK NO ES SENCILLO
    let tmpJourneyParticipants = journey.cycleJourneysParticipants;
    if (tmpJourneyParticipants == null || tmpJourneyParticipants == undefined) {
        tmpJourneyParticipants = journey.cycleJourneyParticipants;
    }

    const cycleJourneysParticipant = tmpJourneyParticipants.find(
      cycleJourneyParticipant => cycleJourneyParticipant.idParticipant == user.idParticipant
    );
    console.log(cycleJourneysParticipant);

      let cambioJornada = false;
    let nextJourney = getNextJourney(cycle);
    if (!isLastJourney && Date.parse(nextJourney.cycleJourneyDateFrom) < Date.now()) {
        cambioJornada = true;
    }

      if (cycleJourneysParticipant?.cycleJourneyAssistance && !cambioJornada) {
          fetchNextTargets({
            idCycleJourney: journey.idCycleJourney,
            idParticipant: user.idParticipant,
            idLanguage: idLang
          });
          setIdJourney(journey.idCycleJourney);
          setJourney(journey);
      } else {
          if (!cambioJornada) {
              checkOtherJourney(journey);
          } else {
              checkOtherJourney(nextJourney);
          }
    }
  }, []);

  useEffect(() => {
    if (journey && idJourney) {
      serviceCycleJourneys
        .getCycleJourneyDetails(idJourney)
        .then(response => {
          if (!response.error) {
            setTargetsOn(response.data.cycleTarget);
          }
        });
  
      serviceCycleJourneysParticipants
        .cycleJourneParticipantDetailsByJourney(idJourney)
        .then(response => {
          if (!response.error && response.data) {
            setParticipantDetails(response.data);
          }
        });
  
      if (cycle.cycleJourneys.length === cycle.cycleJourneys.indexOf(journey) + 1) {
        setDisplayPrep('none');
      }
    }
  }, [journey, idJourney]);

  useEffect(() => {
    if (hasPreviousTargets) {
      setDisplayPrep('block');
    } else {
      setDisplayPrep('none');
    }
  }, [hasPreviousTargets]);

  useEffect(() => {
    if ((!targetsOn || selectedTargets.length >= journey?.cycleTargetMinNum) && hasPreviousTargets) {
      setDisplaySaw('block');
    } else {
      setDisplaySaw('none');
    }
  }, [targetsOn, selectedTargets]);

  useEffect(() => {
    const initialTargetTexts = { ...targetTexts };
    selectedTargets.forEach(target => {
      if (!initialTargetTexts[target.idCycleJourneysTarget]) {
        initialTargetTexts[target.idCycleJourneysTarget] = target.targetText || '';
      }
    });
    setTargetTexts(initialTargetTexts);
  }, [selectedTargets]);

  useEffect(() => {
 
    // Si ya hay objetivos guardados (hasPreviousTargets) y no es una jornada SER, mostrar el botón "NEXT"
    if (hasPreviousTargets && !isSER) {
        setShowNextButton(true);  // Mostrar "NEXT" solo si no es SER
    } else {
        setShowNextButton(false);  // Mostrar "SAVE_TARGETS" o ningún botón si es SER
    }
 }, [hasPreviousTargets, journey]); 

  const checkOtherJourney = async (journey) => {
    if (!journey || journey.idCycleJourney == null) {
      // Si journey es nulo o no tiene idCycleJourney:
      setTargetsOn(false); // Desactivar los objetivos si no hay una jornada válida
      setLoading(false); // Dejar de mostrar el loading
      return;
    }
  
    const { data } = await serviceCycleJourneysParticipants.checkParatrooper(user.idParticipant, journey.idCycleJourney);
    if (data && data.idCycleJourney != 0) {
      setIdJourney(data.idCycleJourney);
      setJourney(data);
      fetchNextTargets({
        idCycleJourney: data.idCycleJourney,
        idParticipant: user.idParticipant,
        idLanguage: idLang
      });
    } else {
      const previousAssistedJourney = await findAssistedJourney(journey);
      if (previousAssistedJourney && previousAssistedJourney.idCycleJourney != 0) {
        setIdJourney(previousAssistedJourney.idCycleJourney);
        setJourney(previousAssistedJourney);
        fetchNextTargets({
          idCycleJourney: previousAssistedJourney.idCycleJourney,
          idParticipant: user.idParticipant,
          idLanguage: idLang
        });
      } else {
        // No hay ninguna jornada a la que asista, desactivar objetivos y dejar de mostrar el loading
        setTargetsOn(false);
        setLoading(false);
      }
    }
  };
  

  const fetchNextTargets = async (dataTargets) => {
    const { data, error } = await serviceParticipantsTargets.getListByJourneyAndParticipant(dataTargets);
    if (!error) {
      let tmpSelectedTargets = [];
      let tmpSelectedParents = data.filter(target => target.existsInParticipants === 1);

      // Recorrer todos los tmpSelectedParents
      for (const parent of tmpSelectedParents) {
        // Si el parent no tiene hijos, agregarlo tal cual a tmpSelectedTargets
        if (parent.childs !== null && parent.childs.length === 0) {
          tmpSelectedTargets.push(parent);
        } else {
          // Si el parent tiene hijos, agregarlos a tmpSelectedTargets
          tmpSelectedTargets.push(...parent.childs);
        }
      }
      if (tmpSelectedTargets.length > 0) {
        setHasPreviousTargets(true);
      } else {
        setHasPreviousTargets(false);
      }
  
      // Guardar los targets originales cuando se cargan
      setOriginalTargets(tmpSelectedTargets);
        
      setSelectedTargets(tmpSelectedTargets);
      setLoading(false);
      setNextTargets(data);
    }
  };

  const haveTargetsChanged = () => {
    if (originalTargets.length !== selectedTargets.length) return true;
  
    // Detecta si algún objetivo en selectedTargets es distinto de originalTargets
    return selectedTargets.some(target => {
      const matchingOriginalTarget = originalTargets.find(
        original => 
          original.idCycleJourneysTarget === target.idCycleJourneysTarget &&
          original.idTarget === target.idTarget &&
          original.targetText === target.targetText
      );
      return !matchingOriginalTarget; // Si no encuentra coincidencia exacta, hubo un cambio
    });
  };
  
  const postTargets = async () => {
    // Validar que los objetivos `isFreeText` tienen texto
    const invalidFreeTexts = selectedTargets.filter(target =>
      target.isFreeText &&
      (!target.targetText || target.targetText.trim() === '')
    );
  
    if (invalidFreeTexts.length > 0) {
      snackActions.error(t('PARTICIPANTS.FREE_TEXT_REQUIRED'));
      return false;
    }
  
    // Actualizar selectedTargets con los valores de targetTexts antes de guardar
    const updatedTargets = selectedTargets.map(target => ({
      ...target,
      targetText: targetTexts[target.idCycleJourneysTarget] || target.targetText
    }));

    var data = {
      idCycleJourney: idJourney,
      idParticipant: user.idParticipant,
      idTargets: updatedTargets.map(target => ({
        idCycleJourneysTarget: target.idCycleJourneysTarget,
        idTarget: target.idTarget,
        targetText: target.targetText
      }))
    };

    if (updatedTargets.length < journey.cycleTargetMinNum) {
      snackActions.error(t('PARTICIPANTS.MIN_TARGETS') + ' ' + journey.cycleTargetMinNum);
      return false;
    } else {
      const response = await serviceParticipantsTargets.postUpdateJourneyTargets(data);
      if (!response.error) {
        setDisplaySaw('block');
        setHasPreviousTargets(true);  // Actualizar hasPreviousTargets a true después de guardar
        return true;
      } else {
        return false;
      }
    }
  };

  const goToActiveJourney = () => {
      navigate('/activeJourney', {
          state: { journey, idJourney }
      });
  };

  const handleContinue = async () => {
    // Desactivar el botón inmediatamente después de iniciar el guardado
    setCheckFree(true);
  
    const saved = await postTargets(); // Guardar los objetivos
    if (saved) {
      // Actualizar el estado después de guardar con éxito
      setHasPreviousTargets(true);
      setCheckFree(false);  // Desactivar el botón después de guardar con éxito
  
      const response = await serviceCycleJourneysParticipants.cycleJourneParticipantDetailsByJourney(idJourney);
      if (!response.error && response.data) {
        const { cycleJourneyParticipantRemarks, cycleJourneyParticipantRate } = response.data;
  
        // Verificar si es la última jornada
        if (isLastJourney) {
          if (cycleJourneyParticipantRemarks && cycleJourneyParticipantRate) {
            // Si es la última jornada y ya se han dado comentarios, no navegar a scoreJourney
            if (journey.idPreparation === 1) {
              navigate("/activeJourney", { state: { journey, idJourney } });
            }
            return;
          } else if (journey.activeAssesment) {
            // Si la valoración está activa pero no completada, navegar a scoreJourney
            navigate("/scoreJourney", {
              state: { journey, idJourney }
            });
          }
        } else {
          // Si no es la última jornada, manejar la navegación según la preparación
          if (journey.activeAssesment) {
            if (cycleJourneyParticipantRemarks && cycleJourneyParticipantRate) {
              if (journey.idPreparation === 1) {
                navigate("/activeJourney", { state: { journey, idJourney } });
              }
              return;
            }
            // Si no hay valoración, navegar a scoreJourney
            navigate("/scoreJourney", { state: { journey, idJourney } });
          } else if (journey.idPreparation !== 0) {
            navigate("/activeJourney", { state: { journey, idJourney } });
          }
        }
      }
    }
  };

  const findAssistedJourney = async (currentJourney) => {
    const currentJourneyIndex = cycle.cycleJourneys.indexOf(currentJourney);
    let previousJourneyIndex = currentJourneyIndex - 1;

    while (previousJourneyIndex >= 0) {
      const previousJourney = cycle.cycleJourneys[previousJourneyIndex];
      const participant = previousJourney.cycleJourneyParticipants.find(
        cycleJourneyParticipant => cycleJourneyParticipant.idParticipant == user.idParticipant
      );

      if (participant && participant.cycleJourneyAssistance) {
        return previousJourney;
      } else {
        const { data } = await serviceCycleJourneysParticipants.checkParatrooper(user.idParticipant, previousJourney.idCycleJourney);
        if (data?.idCycleJourney != 0) {
          return data;
        }
      }
      previousJourneyIndex--;
    }
    return null;
  };

  const handleTargets = (e, idCycleJourneysTarget, idTarget) => {
    const isChecked = e.target.checked;
    
    // Encuntra el target correspondiente para comprobar si es `isFreeText`
    const currentTarget = nextTargets.find(target => 
      target.idCycleJourneysTarget === idCycleJourneysTarget
    );
  
    if (currentTarget?.isFreeText && isChecked) {
      const targetText = targetTexts[idCycleJourneysTarget] || '';
      if (targetText.trim() === '') {
        snackActions.error(t('PARTICIPANTS.TEXT_REQUIRED'));
        return; // No permitir marcar target si no hay texto
      }
    }
  
    let tmpSelectedTargets = [...selectedTargets];
    let pos = tmpSelectedTargets.findIndex(
      x => x.idCycleJourneysTarget === idCycleJourneysTarget && x.idTarget === idTarget
    );
  
    if (pos > -1 && !isChecked) {
      tmpSelectedTargets.splice(pos, 1);
    } else if (isChecked) {
      tmpSelectedTargets.push({
        idCycleJourneysTarget: idCycleJourneysTarget,
        idTarget: idTarget,
        targetText: targetTexts[idCycleJourneysTarget] || ''
      });
    }
    setSelectedTargets(tmpSelectedTargets);
  };

  const checkList = (targetId, idCycleJourneysTarget) => {
    if (selectedTargets.length > 0) {
      const index = selectedTargets.findIndex(target => target.idTarget === targetId && target.idCycleJourneysTarget === idCycleJourneysTarget);
      return index !== -1;
    }
    return false;
  };

  const handleTargetText = (idCycleJourneysTarget, idTarget, text) => {
    setTargetTexts(prevTexts => ({
      ...prevTexts,
      [idCycleJourneysTarget]: text
    }));

    let tmpSelectedTargets = [...selectedTargets];
    let pos = tmpSelectedTargets.findIndex(
      x => x.idCycleJourneysTarget === idCycleJourneysTarget
    );

    if (pos === -1 ) {
      tmpSelectedTargets.push({
        idCycleJourneysTarget: idCycleJourneysTarget,
        idTarget: idTarget,
        targetText: targetTexts[idCycleJourneysTarget] || ''
      });
      setSelectedTargets(tmpSelectedTargets);
    }

    if (text.length === 0) {
      tmpSelectedTargets.splice(pos, 1);
      setSelectedTargets(tmpSelectedTargets);
    }
  };

  const getFreeName = idCycleJourneysTarget => {
    return targetTexts[idCycleJourneysTarget] || '';
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={0} marginTop={1}>
        <Grid item xs={9.5} pt={1}>
          <Typography className={classes.subtitle}>
            {(isLastJourney && journey.idCycle !== null) ? t('PARTICIPANTS.MY_LAST_TARGETS') : t('PARTICIPANTS.MY_TARGETS')}
          </Typography>
        </Grid>
        <Grid item xs={2} paddingLeft={2} paddingTop={0}>
          <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img className={classes.image} src={Logo} alt='logoxs' />
          </Box>
        </Grid>
      </Grid>
      <br />
  
      {/* Mostrar mensaje "NO_TARGETS" si no hay jornada detectada */}
      {(!journey) && (
        <Grid padding={2} marginY={9} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <InfoOutlinedIcon className={classes.colorPrimary} style={{ height: '2em', width: '2em', marginLeft: '1em', marginRight: '1em' }} />
          <Typography className={classes.subtitle}>
            {t('PARTICIPANTS.NO_TARGETS')}
          </Typography>
        </Grid>
      )}
  
      {/* Mostrar el resto solo si journey existe */}
      {targetsOn && journey && (
      <>
        <Grid container spacing={1} padding={1}>
          {cycle &&
            nextTargets?.length > 0 &&
            nextTargets?.map((target, i) => {
              return (
                <Grid
                  item
                  container
                  spacing={1}
                  className={classes.targets}
                  key={'trg-' + target.idCycleJourneysTarget + i}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Grid item xs={target.childs === null ? 12 : 10.5} className={classes.padding0} color={'grey'} fontSize={'1.1em'}>
                    {!target.isFreeText && (
                      <div style={{ color: target.childs === null || target.childs.length === 0 ? '#0085B4' : 'inherit' }}>
                        {target.targetLocaleName.toUpperCase()}
                      </div>
                    )}
                    {target.isFreeText && (
                      <TextField
                        id={target.idCycleJourneysTarget.toString()}
                        fullWidth
                        multiline
                        rows={isLastJourney && journey.idCycle !== null
                          ? (getFreeName(target.idCycleJourneysTarget) ? undefined : 2)
                          : 1}
                        
                        inputRef={(el) => {
                          if (el && !inputRef.current[target.idCycleJourneysTarget]) { //Al seleccionar un target de más abajo se iba arriba el foco
                            inputRef.current[target.idCycleJourneysTarget] = el;
                            el.style.height = "auto"; // Restablece la altura
                            el.style.height = `${el.scrollHeight}px`; // Ajusta al contenido
                          }
                        }}
                        label={target.targetLocaleName}
                        name={'targetText' + target.idCycleJourneysTarget}
                        value={getFreeName(target.idCycleJourneysTarget)}
                        onChange={(e) => {
                          handleTargetText(target.idCycleJourneysTarget, target.idTarget, e.target.value);
                          if (isLastJourney && journey.idCycle !== null) {
                            e.target.style.height = 'auto';
                            e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta la altura al contenido si aplica multilínea
                          }
                        }}
                        size='small'
                        inputProps={{
                          maxLength: 245,
                          style: {
                            overflow: 'hidden',
                            resize: 'none'
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item xs={1.5}
                    className={classes.padding0}
                    sx={{
                      display: target.childs === null || target.childs.length === 0 ? 'flex' : 'none',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      name={target.name}
                      disableRipple
                      checked={checkList(target.idTarget, target.idCycleJourneysTarget)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 48, color: '#0085B4', strokeWidth: '1 !important' } }}
                      onChange={e =>
                        handleTargets(
                          e,
                          target.idCycleJourneysTarget,
                          target.idTarget
                        )
                      }
                    />
                  </Grid>
                  {target.childs.map((child, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={0}
                        key={'thg-' + target.idTarget + '-' + child.idCycleJourneysTarget}
                        mt={1}
                        p={0}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9} className={classes.padding0} color={'grey'} fontSize={'1.1em'}>
                          {!child.isFreeText && (
                            <div style={{ color: '#0085B4' }}>{child.targetLocaleName.toUpperCase()}</div>
                          )}
                          {child.isFreeText && (
                            <TextField
                              id={child.idCycleJourneysTarget.toString()}
                              fullWidth
                              multiline={isLastJourney && journey.idCycle !== null} // Activar multilínea solo si es la última jornada y no es SER
                              rows={isLastJourney && journey.idCycle !== null
                                ? (getFreeName(target.idCycleJourneysTarget) ? undefined : 2)
                                : 1}
                              inputRef={el => {
                                if (inputRef.current) {
                                  inputRef.current[child.idCycleJourneysTarget] = el;
                                }
                              }}
                              label={child.targetLocaleName}
                              name={'targetText' + child.idCycleJourneysTarget}
                              value={getFreeName(child.idCycleJourneysTarget)}
                              onChange={e => {
                                handleTargetText(child.idCycleJourneysTarget, child.idTarget, e.target.value);
                                if (isLastJourney && journey.idCycle !== null) {
                                  e.target.style.height = 'auto';
                                  e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta la altura solo si aplica multilínea
                                }
                              }}
                              size='small'
                              inputProps={{
                                maxLength: 1000,
                                style: {
                                  overflow: 'hidden',
                                  resize: 'none'
                                }
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          className={classes.padding0}
                          ml={child.targetIdParent !== 99 ? 1 : 0}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                          }}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            name={child.name}
                            disableRipple
                            checked={checkList(child.idTarget, child.idCycleJourneysTarget)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 48, color: '#0085B4', strokeWidth: '1 !important' } }}
                            onChange={e =>
                              handleTargets(
                                e,
                                child.idCycleJourneysTarget,
                                child.idTarget
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
        </Grid>

        <Grid
          marginTop={3}
          marginBottom={10}
          marginRight={2}
          display='flex'
          justifyContent='end'
        >
          {/* Mostrar "SAVE TARGETS" cuando es SER y no hay targets guardados */}
          {isSER && !hasPreviousTargets ? (
            <Button
              variant="contained"
              size="large"
              onClick={handleContinue}
              disabled={
                selectedTargets.length < journey.cycleTargetMinNum // Deshabilitar si no se cumplen los mínimos
              }
            >
              {t('BUTTON.SAVE_TARGETS')}
            </Button>
          ) : (
            /* Mostrar lógica previa para NEXT o SAVE TARGETS */
            (!isSER && !participantDetails.cycleJourneyParticipantRemarks && !participantDetails.cycleJourneyParticipantRate) && (
              !isLastJourney && journey.idCycle === null ? (
                <Button
                  variant='contained'
                  size='large'
                  onClick={handleNext}
                  disabled={
                    selectedTargets.length < journey.cycleTargetMinNum || // Verifica el mínimo de objetivos
                    !haveTargetsChanged() // Detecta si hay cambios en los objetivos
                  }
                >
                  {t('BUTTON.NEXT')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  onClick={(hasPreviousTargets && journey?.idCycle != null) ? handleNext : handleContinue}
                  disabled={
                    selectedTargets.length < journey.cycleTargetMinNum
                  }
                >
                  {(hasPreviousTargets && journey?.idCycle != null)
                    ? t('BUTTON.NEXT')
                    : t('BUTTON.SAVE_TARGETS')}
                </Button>
              )
            )
          )}
        </Grid>


        {/* No sale el botón de "Ver preparación..." cuando no la hay. */}
        {journey.idPreparation !== 0 && journey.idPreparation !== null && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>
          <Grid
            item
            xs={10}
            marginTop={0}
            marginBottom={10}
            display={displayPrep}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              className={
                (!hasPreviousTargets && !checkFree) || 
                (journey.activeAssesment && (!participantDetails.cycleJourneyParticipantRemarks || !participantDetails.cycleJourneyParticipantRate))
                ? classes.btnSecondaryDisabled 
                : classes.btnSecondary
              }
              variant='contained'
              size='large'
              disabled={
                (!hasPreviousTargets && !checkFree) || 
                (journey.activeAssesment && (!participantDetails.cycleJourneyParticipantRemarks || !participantDetails.cycleJourneyParticipantRate))
              }
              onClick={goToActiveJourney}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                  {t('PARTICIPANTS.BTNPREPARATION_LINE1')}
                </span>
                <span style={{ fontSize: '1em' }}>
                  {t('PARTICIPANTS.BTNPREPARATION_LINE2')}
                </span>
              </div>
            </Button>
          </Grid>
        </div>
        )}
      </>
        )
      }
    </>
  );
};

export default Targets;
