import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const AvatarParticipant = ({ image, onOpenDialog, width, height }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
        sx={{
            width: `${width}`,
            height: `${height}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: image ? 'none' : '2px dashed #b0b0b0', // Solo muestra el borde cuando no hay imagen
            borderRadius: '10px',
            backgroundColor: hover ? '#e0e0e0' : '#ffffff',  // Fondo gris cuando hay hover, blanco cuando no
            paddingTop: image ? '0' : '70px',
            paddingBottom: image ? '0' : '70px',
            paddingLeft: image ? '0' : '40px',
            paddingRight: image ? '0' : '40px',
        }}
        onClick={onOpenDialog}
        onMouseEnter={() => setHover(true)}  // Cambiar a gris al pasar el ratón
        onMouseLeave={() => setHover(false)}  // Volver a blanco al salir el ratón
    >

      {image ? (
          <img
              src={`data:image/png;base64,${image}`}
              alt="avatar"
              style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',  // Ajuste dinámico de la imagen
              }}
          />
      ) : (
          <PhotoCamera color="primary" />  // Icono cuando no hay imagen
      )}
    </Box>
  );
};

export default AvatarParticipant;