import DeletePopover from '@components/DeletePopover'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, Chip, Grid, MenuItem, Select, Typography, TextField } from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TargetCyclesConfiguration = ({
  targetList,
  getNewJourneys,

  isUpdate,
  journeyIndex,
  data,
  setData
}) => {
  const classes = useCommonStyles()
  const [subObjList, setsubObjList] = useState()
  const journey = data[journeyIndex]
  const { t } = useTranslation()

  // Calculamos el valor máximo de targets en función de journey.cycleJourneyTargets
  const [maxTargets, setMaxTargets] = useState(journey.cycleJourneyTargets.length);

  // Actualizar el valor máximo cuando journey.cycleJourneyTargets cambie
  useEffect(() => {
      setMaxTargets(journey.cycleJourneyTargets.length);
  }, [journey.cycleJourneyTargets]);

  useEffect(() => {
    const filteredList = journey.cycleJourneyTargets.map(selectedTarget => {
      return targetList.find(itemTargetList => {
        return itemTargetList?.idTarget === selectedTarget.idTarget
      })
    })

    setsubObjList([
      {
        idLanguage: 1,
        idTarget: 99,
        idTargetLocale: 99,
        targetLocaleName: t('TEXT.NO_ELEMENTS')
      },
      ...new Set(filteredList)
    ])
  }, [journey.cycleJourneyTargets, targetList])

  const handleChangeTargets = (e, journey, targetIndexSelected) => {
    const newTargetValues = journey.cycleJourneyTargets.map((target, index) => {
      if (index === targetIndexSelected) {
        return {
          ...target,
          [e.target.name]: e.target.value
        }
      }
      return target
    })

    resetSelect(newTargetValues)
    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleJourneyTargets',
      journeyIndex
    )
    setData(newJourneys)
  }
  const addTarget = item => {
    const newTargetValues = [
      ...item.cycleJourneyTargets,
      {
        idTarget: targetList[0].idTarget,
        targetIdParent: 99,
        ...(isUpdate && { idCycleJourney: item.idCycleJourney })
      }
    ]

    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleJourneyTargets',
      journeyIndex
    )

    setData(newJourneys)
  }

    const handleChangeConfiguration = e => {
        let newData = data.map((journey, index) => {
            if (index === journeyIndex) {
                return {
                    ...journey,
                    cycleTargetMinNum: Number(e.target.value)
                };
            }
            return journey;
        });

        // Si el valor actual es igual al máximo, reducir en 1 al quitar un target
        const currentValue = data[journeyIndex].cycleTargetMinNum;
        if (currentValue === maxTargets && e.target.value < currentValue) {
            newData = newData.map((journey, index) => {
                if (index === journeyIndex) {
                    return {
                        ...journey,
                        cycleTargetMinNum: currentValue - 1
                    };
                }
                return journey;
            });
        }

        setData(newData);
    };

    const removeTarget = (journey, targetIndex) => {
        const newTargetValues = journey.cycleJourneyTargets.filter(
            (_, index) => index !== targetIndex
        );

        resetSelect(newTargetValues);

        let newJourneys = getNewJourneys(
            newTargetValues,
            'cycleJourneyTargets',
            journeyIndex
        );

        // Si el valor actual es igual al máximo, reducir en 1 al quitar un target
        const currentValue = data[journeyIndex].cycleTargetMinNum;
        if (currentValue === maxTargets) {
            newJourneys = newJourneys.map((journey, index) => {
                if (index === journeyIndex) {
                    return {
                        ...journey,
                        cycleTargetMinNum: currentValue - 1
                    };
                }
                return journey;
            });
        }

        setData(newJourneys);
    };

  const resetSelect = newTargetValues => {
    //Array con los valores que están en el listado de objetivos
    const permittedValues = newTargetValues.map(value => value.idTarget)
    //Si en listado de subobjetivos hay un objetivo eliminado se cambia al valor a 99 = Ninguno
    newTargetValues.forEach(value => {
      if (!permittedValues.includes(value.targetIdParent)) {
        value.targetIdParent = 99
      }
    })
  }

  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        mb={2}
        className={classes.cardTitle}
      >
        {t('CONFIG_JOURNEYS.TARGET.CONFIG_TARGET').toUpperCase()}
      </Typography>

      {journey.cycleJourneyTargets.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={5.5}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.TARGET.TARGET')}
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.TARGET.SUBTARGET')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.TARGET.NO_ASSIGNED_TARGETS')}
        </Typography>
      )}

      {journey.cycleJourneyTargets.map((target, i) => {
        const filteredSubObjList = subObjList?.filter(subObj => subObj.idTarget !== target.idTarget);
        return (
          <Grid container spacing={1} mb={1} key={`cycleJourneyTarget${i}`}>
            <Grid item xs={5.5}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idTarget'
                value={target.idTarget}
                onChange={e => handleChangeTargets(e, journey, i)}
              >
                {targetList
                  .sort((a, b) => a.targetLocaleName.localeCompare(b.targetLocaleName)) // Ordena alfabéticamente
                  .map(target => (
                    <MenuItem
                      value={target.idTarget}
                      key={target.idTarget}
                      className={classes.menuItem}
                    >
                      {target.targetLocaleName}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid>

            <Grid item xs={5.5}>
              {subObjList && (
                <Select
                  className={classes.select}
                  fullWidth
                  size='small'
                  name='targetIdParent'
                  value={target?.targetIdParent}
                  onChange={e => handleChangeTargets(e, journey, i)}
                >
                  {filteredSubObjList.map(subObj => {
                    return (
                    <MenuItem
                      value={subObj.idTarget || 99}
                      key={subObj.idTarget}
                      className={classes.menuItem}
                    >
                      {subObj.targetLocaleName}
                    </MenuItem>
                    )}
                  )}
                </Select>
              )}
            </Grid>

            <Grid item xs={1}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeTarget(journey, i)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}
      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addTarget(journey)
          }}
          label={t('CONFIG_JOURNEYS.TARGET.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
          <Box my={2}>
              <Typography variant='caption' component='div'>
                  {t('CONFIG_JOURNEYS.TARGET.MINIMUN_OBJ')}
              </Typography>
              <TextField
                  placeholder='0'
                  type='number'
                  name='cycleTargetMinNum'
                  size='small'
                  value={journey.cycleTargetMinNum}
                  inputProps={{
                      inputMode: 'numeric',
                      min: 0,
                      max: maxTargets // Utilizar el valor máximo de journey.cycleJourneyTargets
                  }}
                  onChange={handleChangeConfiguration}
              />
          </Box>
    </Fragment>
  )
}

export default TargetCyclesConfiguration
