import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import SectionForm from "./SectionForm";
import SectionCycleJourneysTabs from "../cyclesJourneys/SectionCycleJourneysTabs";
import SectionInvited from "./SectionInvited";
import SectionParticipants from "./SectionParticipants";
import serviceCycleTypes from "@services/serviceCycleTypes";
import PreparationConfiguration from "../cyclesJourneys/PreparationConfiguration";

const TabConfiguration = (props) => {  
  const idCycleType = props?.formData ? props.formData?.idCycleType : null;

  useEffect(() => {
    updatePreparation(props.journeyTypeModeLive);
  }, [props.journeyTypeModeLive]);

  // useEffect(() => {
  //   if (props.cycleJourneys) {
  //     props.setJourneyTypeModeLive(props.cycleJourneys.map(j => j.idCycleJourneyModality));
  //   }
  // }, [props.cycleJourneys]);
  
  const updatePreparation = (journeyModes) => {
    const newIdPreparation = journeyModes;
    props.setIdPreparation(newIdPreparation);
  };

  const fetchCycleTypeDetails = async () => {
    const { data } = await serviceCycleTypes.getCycleTypeDetail(idCycleType);

    const arrayString = JSON.stringify(data);

    props.setCycleIdPreparations(data?.cycleTypeJourneys?.map((journey) => {
      return {
        cycleTypeJourneyPIdPreparation: journey.cycleTypeJourneyPIdPreparation,
        cycleTypeJourneyDIdPreparation: journey.cycleTypeJourneyDIdPreparation,
      };
    })) 

    sessionStorage.setItem("cycleTypeDetail", arrayString);
  };

  useEffect(() => {
    if (idCycleType !== null) {
      fetchCycleTypeDetails(idCycleType);
    }
  }, [idCycleType]);

  return (
    <Grid container spacing={3} mt={2}>
      <Grid item xs={12}>
        <SectionForm {...props} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <SectionInvited {...props} />
          </Grid>
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <SectionParticipants {...props} />
          </Grid>
        </Grid>
      </Grid>
      <SectionCycleJourneysTabs {...props} />
    </Grid>
  );
};

export default TabConfiguration;
