import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, Button, CircularProgress, Grid, Slider, Typography } from '@mui/material';
import serviceParticipants from '@services/serviceParticipants';
import { useTranslation } from 'react-i18next';
import FileBase64 from 'react-file-base64';
import AvatarEditor from 'react-avatar-editor';
import { useResizeDetector } from 'react-resize-detector';
import { useHandleImages } from '@hooks/useHandleImages';
import { snackActions } from '@helpers/snackbarUtils';
import Loading from "@components/Loading";

const ChangeImageParticipant = ({ idParticipant, imageName, onImageSave, localImage }) => {
    const editor = useRef(null);
    const { t } = useTranslation();
    const { width, height } = useResizeDetector();
    const [croppedScale, setCroppedScale] = useState(1);
    const [croppedRotate, setCroppedRotate] = useState(0);
    const [details, setDetails] = useState({ participantImage: null });
    const [loading, setLoading] = useState(true);
    const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
    const { manageImages, infoData, setInfoData } = useHandleImages();

    const fetchData = async (idParticipant) => {
      try {
          const { data, error } = await serviceParticipants.getParticipantsDetails(idParticipant);
  
          let participantImage = data.participantImage || null;
  
          // Intentar obtener la imagen si no está en la respuesta principal
          if (!participantImage) {
              const imageResponse = await serviceParticipants.getParticipantImage(idParticipant);
              if (!imageResponse.error) {
                  participantImage = imageResponse.data || null;
              }
          }
  
          setDetails(prevState => ({
              ...prevState,
              ...data,
              participantImage, // Asegúrate de que participantImage esté correctamente asignado
          }));
      } catch (error) {
          console.error('Error al obtener detalles del participante:', error);
      }
    };

    useEffect(() => {
      let isMounted = true;

      if (idParticipant) {
          setLoading(true);
          fetchData(idParticipant)
              .then(() => {
                  if (isMounted) {
                      setLoading(false);
                  }
              })
              .catch(() => {
                  if (isMounted) {
                      setLoading(false);
                  }
              });
      } else {
          setLoading(false);
      }

      return () => {
          isMounted = false;
      };
    }, [idParticipant]);

    useEffect(() => {
        if (infoData !== undefined) {
            setDetails(prevState => ({
                ...prevState,
                [imageName]: infoData[imageName]
            }));
        }
    }, [infoData]);

    useEffect(() => {
        if (!idParticipant && localImage) {
            setDetails(prevState => ({
                ...prevState,
                [imageName]: localImage
            }));
        }
    }, [idParticipant, localImage, imageName]);

    

    const saveImage = () => {
      setLoading(true);
  
      // Crear un canvas con la resolución deseada
      const canvas = document.createElement('canvas');
      canvas.width = 768;
      canvas.height = 1024;
  
      const ctx = canvas.getContext('2d');
  
      // Dibujar la imagen escalada desde el editor
      if (editor.current) {
          const scaledImage = editor.current.getImageScaledToCanvas();
          ctx.drawImage(scaledImage, 0, 0, 768, 1024);
  
          // Convertir el canvas a base64
          const image = canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
  
          // Guardar la imagen localmente
          setDetails(prevState => ({
              ...prevState,
              [imageName]: image,
          }));
  
          // Notificar al componente padre
          if (onImageSave) onImageSave(image);
      }
  
      setLoading(false);
      snackActions.success(t('LABEL.SAVE_LOCALE'));
    };

    const deleteImage = () => {
      setLoading(true);

      // Actualizar el estado local
      setDetails(prevState => ({
          ...prevState,
          [imageName]: null,
      }));
      setLoading(false);

      // Notificar al componente padre que la imagen se eliminó localmente
      if (onImageSave) {
          onImageSave(null);
      }
    };

    const handleChangeImage = e => {
        manageImages(e, imageName);
    };

    const handleEditorLoad = (image) => {
        setOriginalSize({ width: image.width, height: image.height });
        setLoading(false);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '35vh', paddingTop: '50px' }}>
                <Loading />
            </div>
        );
    }

    const handleScaleChange = (e, newScale) => {
        setCroppedScale(newScale);
        const canvas = editor.current.getImage()?.canvas;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const image = new Image();
                image.src = details[imageName];
                image.onload = () => {
                    const newX = (width - (image.width * newScale)) / 2;
                    const newY = (height - (image.height * newScale)) / 2;
                    ctx.drawImage(image, newX, newY, image.width * newScale, image.height * newScale);
                };
                setInfoData(prevState => ({
                    ...prevState,
                    [imageName]: image
                }));
            }
        }        
    };

    return (
        <>            
            {(details?.participantImage || localImage) && (
              <Grid
                  container
                  spacing={1}
                  sx={{
                      mt: 5, // Margen superior por defecto para todas las resoluciones menos para...
                      '@media (max-width: 400px)': {
                          mt: 20, // Margen superior de 20 solo para pantallas menores o iguales a 400px
                      },
                  }}
              >
                  <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                      <AvatarEditor
                          ref={editor}
                          image={
                              details?.[imageName]
                                  ? `data:image/png;base64,${details?.[imageName]}`
                                  : `data:image/png;base64,${localImage}`
                          }
                          width={200}
                          height={250}
                          border={0}
                          color={[255, 255, 255, 0.8]}
                          scale={croppedScale}
                          rotate={croppedRotate}
                          onLoadSuccess={handleEditorLoad}
                          style={{ objectFit: 'contain' }}
                      />
                  </Grid>
              </Grid>
            )}
            
            {(details?.participantImage || localImage) && (
              <Grid container spacing={5}>
                  <Grid item xs={6} style={{ textAlign: 'center', marginTop: '10px' }}>
                      <Typography variant="subtitle1" gutterBottom sx={{ mb: 0 }}>
                        Zoom
                      </Typography>
                      <Slider
                          value={croppedScale}
                          min={0.1}
                          max={2}
                          step={0.01}
                          valueLabelDisplay="auto"
                          onChange={(e, newValue) => handleScaleChange(e, newValue)}
                          aria-labelledby="continuous-slider"
                          marks={[
                            {
                                value: 0.1,
                                label: '0.1',
                            },
                            {
                                value: 1, // Valor medio
                                label: '1', // Etiqueta para el valor medio
                            },
                            {
                                value: 2,
                                label: '2',
                            }
                        ]}
                      />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center', marginTop: '10px' }}>
                      <Typography variant="subtitle1" gutterBottom sx={{ mb: 0 }}>
                          {t('LABEL.ROTATE')}
                      </Typography>
                      <Slider
                          value={croppedRotate}
                          min={-180}
                          max={180}
                          step={0.5}
                          onChange={(event, newValue) => setCroppedRotate(newValue)}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                          marks={[
                            {
                                value: -180,
                                label: '-180',
                            },
                            {
                                value: 0, // Valor medio
                                label: '0', // Etiqueta para el valor medio
                            },
                            {
                                value: 180,
                                label: '180',
                            }
                        ]}
                      />
                  </Grid>
              </Grid>
            )}

            <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ marginTop: '1px' }}>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => {
                            document.querySelector('#upload>input').click();
                        }}
                    >
                        {t('CYCLES.UPLOAD_PHOTO')}
                    </Button>
                </Grid>
                {(details?.participantImage || localImage) && (
                    <Grid item>
                        <Button variant="contained" onClick={saveImage} color="success">
                            {t('BUTTON.SAVE')}
                        </Button>
                    </Grid>
                )}
                {(details?.[imageName] || localImage) && (
                    <Grid item>
                        <Button variant="contained" color="error" onClick={deleteImage}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Grid>
                )}
            </Grid>

            <div id="upload" style={{ display: 'none' }}>
                <FileBase64 multiple={false} onDone={handleChangeImage} />
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default ChangeImageParticipant;
