import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import { useForm } from '@hooks/useForm'
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material'
import serviceCompanies from '@services/serviceCompanies'
import serviceList from '@services/serviceList'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Card from '@components/Card'
import Avatar from '@components/Avatar'
import DynamicTable from '@components/DynamicTable'
import AutocompleteSearch from '@components/AutocompleteSearch'
import Moment from 'react-moment'
import { useHandleImages } from '@hooks/useHandleImages'
import Loading from '@components/Loading'

const CompanyDetail = () => {
  const {
    state: { data: COMPANY_DATA, type: TYPE, idCompany: ID_COMPANY }
  } = useLocation()

  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const classes = useCommonStyles()
  const { t } = useTranslation()

  const [openDialog, setOpenDialog] = useState(false)
  const { formData, setFormData, handleChange } = useForm(COMPANY_DATA || {})
  const { manageImages, infoData } = useHandleImages()
  const [companyLogo, setCompanyLogo] = useState(null);


  const HEADER_TITLE =
    TYPE === 'NEW' ? t('COMPANY.NEW_TITLE') : t('COMPANY.UPDATE_TITLE')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('COMPANY.NEW_SUBTITLE') : t('COMPANY.UPDATE_SUBTITLE')

  const disabledSaveButton = COMPANY_DATA === formData
  const disabledInput = TYPE === 'INFO'
  const columnSearch = [
    {
      SearchField: 'IdCompany',
      SearchValue: ID_COMPANY,
      SearchMode: 'EqualTo'
    }
  ]

  const fetchCompanyLogo = async (idCompany) => {
    try {
        const { data, error } = await serviceCompanies.getCompanyImage(idCompany);
        if (!error && data) {
            setCompanyLogo(`data:image/png;base64,${data}`);
        }
    } catch (error) {
        console.error(`Error al cargar el logo de la empresa: ${error}`);
    }
    };

    useEffect(() => {
        const fetchCompanyLogo = async () => {
            try {
                const response = await serviceCompanies.getCompanyImage(ID_COMPANY);
                if (!response.error && response.data) {
                    setFormData(prevState => {
                        return { ...prevState, companyLogo: response.data }
                    })
                }
            } catch (error) {
                console.error("Error al obtener el logo de la empresa:", error);
            }
        };

        if (ID_COMPANY) {
            fetchCompanyLogo();
        }
    }, [ID_COMPANY]);

  useEffect(() => {
    if (
      infoData?.companyLogo !== undefined &&
      infoData?.companyLogo !== null &&
      infoData?.companyLogo.length !== 0
    ) {
      setFormData(prevState => {
        return { ...prevState, companyLogo: infoData.companyLogo }
      })
    }
  }, [infoData])

  // Funciones para select con autob�squeda empresas
  const [companySelected, setCompanySelected] = useState()

  useEffect(() => {
    if (companySelected) {
      handleSelect(companySelected)
    } else if (companySelected === null || companySelected === undefined) {
      handleSelect('')
    }
  }, [companySelected])

  const handleSelect = companySelected => {
    setFormData(prevState => {
      return {
        ...prevState,
        idCompanyParent: companySelected.idCompany
      }
    })
  }

  const [countries, setCountries] = useState([])
  useEffect(() => {
    serviceList.getCountriesListAll().then(response => {
      if (!response.error) {
        setCountries(response.data)
      }
    })
    setLoading(false)
  }, [])

  const cleanDebounce = data => {
    if (data.globalSearch) data.search = []
    return data
  }
  // Fin funciones para select con autob�squeda empresas

  const ORDER = [
    {
      orderField: 'cycleName',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      accessor: 'idCycle',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%',
      hiddenColumn: true
    },
    {
      accessor: 'cycleName',
      Header: t('CYCLES.CYCLE_NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleTypeCode',
      Header: t('TEXT.CYCLE_TYPE'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleDateFrom',
      Header: t('LABEL.DATE_INIT'),
      Cell: props => (
        <Typography variant='body2'>
          <Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment>
        </Typography>
      )
    }
  ]

  const ORDER_PARTICIPANTS = [
    {
      orderField: 'participantName',
      orderAsc: true
    }
  ]
  const COLUMNS_PARTICIPANTS = [
    {
      accessor: 'idParticipant',
      Header: t('LABEL.ID'),
      filterable: false,
      hiddenColumn: true,
      width: '5%'
    },
    {
      accessor: 'participantName',
      Header: t('LABEL.NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantSurname',
      Header: t('LABEL.SURNAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantEmail',
      Header: t('LABEL.EMAIL'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cyclesList',
      Header: t('LABEL.CYCLE'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    }
  ]

  /*const handleError = data => {
        disabledSaveButton  = (data === "") ? true : false; 
        return disabledSaveButton;
    };*/

  const handleSubmit = type => {
    setLoading(true)
  
    // Verifica que idCompany esté en formData para la actualización
    if (type === 'UPDATE' && !formData.idCompany) {
      formData.idCompany = COMPANY_DATA.idCompany || formData.idCompany
    }
  
    switch (type) {
      case 'NEW':
        serviceCompanies.newCompany(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case 'UPDATE':
        serviceCompanies.updateCompany(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case 'DELETE':
        serviceCompanies.deleteCompany(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        break
    }
  }

  const handleResponse = (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
        navigate('/companies');
      } else if (actionType === 'SAVE') { //Al Crear nuevo o Actualizar se queda en la página
        if (TYPE === 'NEW') {
          sessionStorage.setItem('companyNew', JSON.stringify(response.data))
          serviceCompanies.getCompaniesDetails(response.data.idCompany).then(response => {
            if (!response.error) {
              setFormData(response.data) // Actualiza formData con los datos de la compañía incluyendo idCompany
              navigate('/companyDetail', {
                state: { data: response.data, type: 'UPDATE', idCompany: response.data.idCompany },
              })
            }
          })
        }
      }
    }
  }

  // const handleChangeImage = e => {
  //   const image = e.base64.replace('data:' + e.type + ';base64,', '')
  //   setFormData(prevState => {
  //     return { ...prevState, companyLogo: image }
  //   })
  // }

  const fetchCompanies = useCallback(
    async debounceValue =>
      await serviceCompanies.getCompanies(
        cleanDebounce(debounceValue)
      ),
    []
  );

  //Poniendo aquí debajo este UseEffect funciona que salga la Empresa matriz.
  useEffect(() => {
    if (COMPANY_DATA) {
      setFormData(COMPANY_DATA)
    }
  }, [COMPANY_DATA])

  if (loading) {
    return <Loading />
  }
  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_COMPANY')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        {!disabledInput && (
          <FormActions
            show={TYPE === 'UPDATE' && formData.idCompany != 0}
            handleDelete={() => setOpenDialog(true)}
            handleUndo={() => setFormData(COMPANY_DATA)}
            handleSave={() => handleSubmit(TYPE)}
            disabledButton={disabledSaveButton || formData.idCompany == 0}
            urlBack='/companies'
          />
        )}
      </HeaderTitle>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card fullHeight>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={12}>
                <Typography color='primary' variant='subtitle1' mb={1}>
                  Logo
                </Typography>
                <Avatar
                  handleChange={e => manageImages(e, 'companyLogo')}
                  image={formData?.companyLogo}
                  width={166.5}
                  height={68}
                  idbutton={'upload-button-companyLogo'}
                />
              </Grid>

              <Grid item lg={9} xs={12}>
                <Typography color='primary' variant='subtitle1' mb={1}>
                  {t('FORM.BUSINESS_DATA')}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      label={t('LABEL.COMPANY')}
                      name='companyName'
                      value={formData.companyName || ''}
                      size='small'
                      onChange={handleChange}
                      error={formData.companyName === ''}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label={t('LABEL.ADDRESS')}
                      name='companyAddress'
                      value={formData.companyAddress || ''}
                      error={formData.companyAddress === ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      error={formData.companyCity === ''}
                      label={t('LABEL.CITY')}
                      name='companyCity'
                      value={formData.companyCity || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={formData.companyZip === ''}
                      label={t('LABEL.POSTCODE')}
                      name='companyZip'
                      value={formData.companyZip || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={12} display='flex'>
                    <FormControl size='small' margin='dense' fullWidth>
                      <InputLabel id='country'>
                        {t('LABEL.COUNTRY')}*
                      </InputLabel>
                      <Select
                        required
                        labelId='country'
                        label={t('LABEL.COUNTRY')}
                        value={formData?.idCountry || ''}
                        name='idCountry'
                        onChange={handleChange}
                        size='small'
                      >
                        {countries.map((country, i) => (
                          <MenuItem key={i} value={country.idCountry}>
                            {country.countryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      error={formData.companyEmail === ''}
                      label={t('LABEL.EMAIL')}
                      name='companyEmail'
                      value={formData.companyEmail || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={formData.companyPhone === ''}
                      label={t('LABEL.PHONE')}
                      name='companyPhone'
                      value={formData.companyPhone || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label={t('LABEL.WEB')}
                      name='companySite'
                      value={formData.companySite || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label={t('LABEL.FAX')}
                      name='companyFax'
                      value={formData.companyFax || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      disabled={disabledInput}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography color='primary' variant='subtitle1'>
                      {t('FORM.PARENT_COMPANY')} *
                    </Typography>
                    <AutocompleteSearch
                      pageSize={10}
                      handleSelect={handleSelect}
                      optionSelected={companySelected}
                      initialSearch={
                        formData.idCompanyParent && {
                          globalSearch: '',
                          order: [],
                          pageNumber: 0,
                          pageSize: 1,
                          search: [
                            {
                              searchField: 'idCompany',
                              searchMode: 'equalTo',
                              searchValue: formData.idCompanyParent
                            }
                          ]
                        }
                      }
                      disabled={false}
                      setOptionSelected={setCompanySelected}
                      setDisabledAddButton={() => {
                        return false
                      }}
                      fetch={fetchCompanies}
                      optionsDisabled={option => {
                        return []
                      }}
                      optionsLabel={option => `${option.companyName || ''}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card fullHeight>
            <Typography color='primary' variant='subtitle1' mb={1}>
              {t('FORM.CONTACT_DATA')}
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label={t('LABEL.CONTACT_PERSON')}
                  name='companyContact'
                  value={formData.companyContact || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={disabledInput}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  label={t('LABEL.CONTACT_PHONE')}
                  name='companyContactPhone'
                  value={formData.companyContactPhone || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={disabledInput}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  label={t('LABEL.CONTACT_EMAIL')}
                  name='companyContactEmail'
                  value={formData.companyContactEmail || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled={disabledInput}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>

            <Typography color='primary' variant='subtitle1' my={1}>
              {t('FORM.OTHER_DATA')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item lg={4} xs={12}>
                <TextField
                  label={t('LABEL.CODE')}
                  name='idCompany'
                  value={formData.idCompany || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  label={t('LABEL.UPDATE_DATE')}
                  name='companyDateUpdated'
                  value={
                    formData.companyDateUpdated
                      ? moment(formData.companyDateUpdated).format('DD-MM-YYYY')
                      : ''
                  }
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item lg={12} xs={12} my={1}>
                <TextField
                  label={t('LABEL.UPDATED_BY')}
                  name='companyUpdatedRemarks'
                  value={formData.companyUpdatedRemarks || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} pt={4}>
        <Grid item md={6} xs={12}>
          <HeaderTitle subtitle={t('TEXT.SUBTITLE_PARTICIPANTS')}></HeaderTitle>
          <DynamicTable
            columns={COLUMNS_PARTICIPANTS}
            endpoint='Participants/ParticipantsCyclesGrouped'
            isGlobalSearch={false}
            isColumnsSearch={false}
            isOrdered={true}
            columnSearchParam={columnSearch}
            pageSizeParam={5}
            orderColumn={ORDER_PARTICIPANTS}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <HeaderTitle subtitle={t('TEXT.SUBTITLE_CYCLES')}></HeaderTitle>
          <DynamicTable
            columns={COLUMNS}
            endpoint='Cycles/List'
            isGlobalSearch={false}
            isColumnsSearch={false}
            isOrdered={true}
            columnSearchParam={columnSearch}
            pageSizeParam={5}
            orderColumn={ORDER}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyDetail
