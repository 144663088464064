import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, Button, CircularProgress, Grid, Slider, Typography } from '@mui/material';
import serviceCycles from '@services/serviceCycles';
import { useTranslation } from 'react-i18next';
import FileBase64 from 'react-file-base64';
import AvatarEditor from 'react-avatar-editor';
import { useResizeDetector } from 'react-resize-detector';
import { useHandleImages } from '@hooks/useHandleImages'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from "@components/Loading"

// onImageSave guarda imagen local para cuando es un grupo nuevo
const ChangeImage = ({ idCycle, imageName, onImageSave, localImage }) => {
    const editor = useRef(null);
    const { t } = useTranslation();
    const { width, height } = useResizeDetector();
    const [croppedScale, setCroppedScale] = useState(1);
    const [croppedRotate, setCroppedRotate] = useState(0);
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
    const { manageImages, infoData, setInfoData } = useHandleImages()

    const fetchData = async (idCycle) => {
        const { data, error } = await serviceCycles.getCycleDetail(idCycle, true, false);
        if (!error) {
            setDetails(data);
        }
    };

    useEffect(() => {
      let isMounted = true; // Verifica el montaje
  
      if (idCycle) {
          setLoading(true); // Activa el estado de carga solo si idCycle está definido
          fetchData(idCycle).then(() => {
              if (isMounted) {
                  setLoading(false); // Desactiva el estado de carga cuando los datos están listos
              }
          }).catch(() => {
              if (isMounted) {
                  setLoading(false);
              }
          });
      } else {
          setLoading(false);
      }
  
      return () => {
          isMounted = false; // Marca el componente como desmontado para evitar actualizaciones de estado
      };
    },[idCycle]);


    useEffect(() => {
        if (infoData !== undefined) {
            setDetails(prevState => {
                return { ...prevState, [imageName]: infoData[imageName] };
            })
        }
    }, [infoData]);

    useEffect(() => {
      if (!idCycle && localImage) {
          setDetails(prevState => ({
              ...prevState,
              [imageName]: localImage
          }));
      }
    }, [idCycle, localImage, imageName]);

    const saveImage = () => {
      setLoading(true)  
      const img = editor.current.getImageScaledToCanvas().toDataURL();
      const image = img.replace('data:image/png;base64,','');
      
      if (idCycle) {
          // Caso donde idCycle existe
          const updatedDetails = { ...details, [imageName]: image };  
          serviceCycles.updateCycle(updatedDetails)
              .then(response => {
                  if (!response.error) {
                      setDetails(updatedDetails);
                      sessionStorage.setItem('details', updatedDetails.cycleImage);
                  } else {
                      snackActions.error(t('CYCLES.ERROR_PICTURE'));
                  }
              })
              .catch(error => {
                  snackActions.error(t('CYCLES.ERROR_PICTURE'));
              })
              .finally(() => setLoading(false)); // Finaliza el estado de carga
      } else {
          // Caso donde idCycle no existe: actualización del estado local
          console.log('No idCycle, updating local state with image.');
          setDetails(prevState => ({
              ...prevState,
              [imageName]: image
          }));
          setLoading(false); // Finaliza el estado de carga inmediatamente
        
          // Llama al callback con la nueva imagen si es proporcionado
          if(onImageSave) onImageSave(image);
            snackActions.success(t('LABEL.SAVE_LOCALE'));
      }
    };

    const deleteImage = () => {
      setLoading(true);
      if (idCycle) {
          // Si idCycle existe, eliminar la imagen del ciclo
          const updatedDetails = { ...details, [imageName]: null };  // Usar null en lugar de '[NULL]'
          serviceCycles.updateCycle(updatedDetails)
              .then(response => {
                  if (!response.error) {
                      setDetails(updatedDetails);
                      sessionStorage.setItem('details', null);  // Almacena null en lugar de '[NULL]'
                  }
              })
              .finally(() => setLoading(false));
      } else {
          // Si idCycle no existe, solo actualiza el estado local
          setDetails(prevState => ({
              ...prevState,
              [imageName]: null  // Usar null en lugar de '[NULL]'
          }));
          setLoading(false);
          snackActions.success(t('CYCLES.DELETE_SUCCESS'));
      }
    };

  const handleChangeImage = e => {
      manageImages(e, imageName)
  };

  const handleEditorLoad = (image) => {
      // Save the original size of the loaded image
      setOriginalSize({ width: image.width, height: image.height });
      setLoading(false); // Si la imagen ha cargado, ocultar el indicador de carga
  };

  // Muestra el indicador de carga si el estado loading es true
  if (loading) {
      return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '35vh', paddingTop: '50px' }}>
          <Loading />
          </div>
      );
      }

  const handleScaleChange = (e, newScale) => {
      // Update the scale state
      setCroppedScale(newScale);
      // Redraw the editor with the new scale
      const canvas = editor.current.getImage()?.canvas;
      if (canvas) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              const image = new Image();
              image.src = details[imageName];
              image.onload = () => {
                  const newX = (width - (image.width * newScale)) / 2;
                  const newY = (height - (image.height * newScale)) / 2;
                  ctx.drawImage(image, newX, newY, image.width * newScale, image.height * newScale);
              };
              setInfoData((prevState) => {
                  return { ...prevState, [imageName]: image };
              })
          }
      }
  };

  return (
    <>
            {(details?.cycleImage || localImage) && (
              <Grid
                  container
                  spacing={1}
                  sx={{
                      mt: 5, // Margen superior por defecto para todas las resoluciones menos para...
                      '@media (max-width: 400px)': {
                          mt: 20, // Margen superior de 20 solo para pantallas menores o iguales a 400px
                      },
                  }}
              >
            <Grid item xs={12} display={'flex'} justifyContent={'center'} >
                <AvatarEditor
                    ref={editor}
                    image={details?.[imageName] ? `data:image/png;base64,${details?.[imageName]}` : null}
                    width={Number.isNaN(width) || width === undefined ? window.innerWidth : width }
                    height={Number.isNaN(width) || width === undefined ? 75 * 2 : (width / 4) * 2} // Aumenta el alto un 50%
                    border={0}
                    color={[255, 255, 255, 0.8]} // RGBA
                    scale={croppedScale}
                    rotate={croppedRotate}
                    onLoadSuccess={handleEditorLoad} // Llamada cuando la imagen se carga correctamente
                />
            </Grid>
        </Grid>
				)}
        
				{(details?.cycleImage || localImage) && (
              <Grid container spacing={5}>
                  <Grid item xs={6} style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Typography variant="subtitle1" gutterBottom>Zoom</Typography>
                <Slider
                    value={croppedScale}
                    min={0.1}
                    max={2}
                    step={0.01}
                    valueLabelDisplay="auto"
                    onChange={(e, newValue) => handleScaleChange(e, newValue)}
                    aria-labelledby="continuous-slider"
                    marks={[
                        {
                            value: 0.1,
                            label: '0.1',
                        },
                        {
                            value: 1, // Valor medio
                            label: '1', // Etiqueta para el valor medio
                        },
                        {
                            value: 2,
                            label: '2',
                        }
                    ]}
                    />
            </Grid>            
            <Grid item xs={6} style={{ textAlign: 'center', marginTop: '10px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    {t('LABEL.ROTATE')}
                </Typography>
                <Slider
                    value={croppedRotate}
                    min={-180}
                    max={180}
                    step={0.5}
                    onChange={(event, newValue) => setCroppedRotate(newValue)}
                    valueLabelDisplay="auto"
                    aria-labelledby="continuous-slider"
                    marks={[
                        {
                            value: -180,
                            label: '-180',
                        },
                        {
                            value: 0, // Valor medio
                            label: '0', // Etiqueta para el valor medio
                        },
                        {
                            value: 180,
                            label: '180',
                        }
                    ]}
                />
            </Grid>
        </Grid>
				)}
            <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ marginTop: '1px' }}>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => {
                            document.querySelector('#upload>input').click();
                        }}
                    >
                        {t('CYCLES.UPLOAD_PHOTO')}
                    </Button>
                </Grid>
								                {(details?.cycleImage || localImage) && (
                    <Grid item>
                        <Button variant="contained" onClick={saveImage} color="success">
                            {t('BUTTON.SAVE')}
                        </Button>
                    </Grid>
                )}
                {(details?.[imageName] || localImage) && (
                    <Grid item>
                        <Button variant="contained" color="error" onClick={deleteImage}>
                            {t('BUTTON.DELETE')}
                        </Button>
                    </Grid>
                )}
            </Grid>

        <div id="upload" style={{ display: 'none' }}>
            <FileBase64 multiple={false} onDone={handleChangeImage} />
        </div><br /><br />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}

export default ChangeImage