import React, { useState, useEffect} from 'react'
import { Button, Box, Typography } from '@mui/material';
import { Add, AccessTimeFilled } from '@mui/icons-material';

const EvButton = ({type, size, color, background, borderColor, disabled, onClick, displayX, dateEnd, extended, disablePointer, participant}) => {
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const date = new Date()
    setFinished(date >= Date.parse(dateEnd))
  }, [dateEnd])
  
  const colorEv = () => {
    if (background === 'full' || borderColor  === 'full') {
      return '#00CC99'
    } else if (background === 'half' || borderColor  === 'half') {
      return '#FF9933'
    } else if (background === 'empty' || borderColor  === 'empty') {
      return '#FC4646'
    }
  } 

  return (
    <div 
      style={{
        position:'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: disablePointer ? "default" : displayX ? "inherit" : "pointer", // Control del cursor según donde esté el botón
      }} 
      onClick={onClick}
    >
      <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            backgroundColor: finished && type === 1 
                              ? '#0085b4' 
                              : finished && type === 2 && !displayX
                              ? colorEv()
                              : `${background} !important`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: displayX 
                            ? 'lightgrey'
                            : finished && type === 1 
                            ? '#0085b4' 
                            : finished && type === 2 && !displayX
                            ? colorEv()
                            : `${borderColor} !important`,
            height: size,
            minWidth: size,
            width: size,
            flexShrink: 0,
            position: 'relative'
        }}
        disabled={disabled} 
      >
        <Typography
          color={displayX ? 'lightgrey' 
                  : finished && type === 1 ? 'white !important'
                  : color}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: `calc(${size}/2)`,
            fontWeight: '600',
            height: '100%',
            minWidth: '100%',
          }}
        >
          EV
        </Typography>
      </Box>
      <div style={{
        position: 'absolute',
        display: displayX ? 'flex': 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: size,
        height: size,
        backgroundColor: 'transparent',
        zIndex: '99',
      }}>
        <div style={{
          position: 'absolute',
          width: '130%',
          height: '2px',
          backgroundColor: 'lightgrey',
          top: '50%',
          transform: `rotate(-45deg)`
        }}></div>
        <div style={{
          position: 'absolute',
          width: '130%',
          height: '2px',
          backgroundColor: 'lightgrey',
          top: '50%',
          transform: `rotate(45deg)`
        }}></div>
      </div>
      <div style={{
        position: 'absolute',
        display: extended ? 'flex': 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: size,
        height: size,
        left: `calc(${size}/7)`,
        top: `calc(${size}/2.8)`,
        backgroundColor: 'transparent',
        zIndex: '99',
      }}>
        <Add sx={{ 
          color: '#0085b4', 
          fontSize: `calc(${size}/2.5)` ,
          marginRight: '-0.2em'         
        }}/>
        
        {/* Esto es para poner la condición de cuando sale sólo el icono del "+" o los dos */}
        {(() => {
          const currentDate = new Date();
          const endDate = new Date(dateEnd);
          const extraTimeEnd = participant?.extraTimeLimit
            ? new Date(endDate.getTime() + participant.extraTimeLimit * 24 * 60 * 60 * 1000) // Sumar extraTimeLimit en días
            : endDate;

          // Ajustar la hora al final del día
          extraTimeEnd.setHours(23, 59, 59, 999);          
          
          //console.log(`Fecha actual: ${currentDate}`);
          //console.log(`Fecha final Medidor: ${endDate}`);
          //console.log(`Fecha extendida del medidor (con extraTimeLimit): ${extraTimeEnd}`);          

          return currentDate <= extraTimeEnd && (
            <AccessTimeFilled sx={{ 
              color: '#0085b4', 
              fontSize: `calc(${size}/2)` }}/>
          );
        })()}

      </div>  
    </div>
  )
}

export default EvButton;