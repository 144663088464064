const ASSISTENZA_E_GUASTI = `

  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="Content-Style-Type" content="text/css" />
      <meta name="generator" content="Aspose.Words for .NET 24.8.0" />
      <title>
      </title>
      <style type="text/css">.asistencia-span { font-size: 16pt; }

        //body { line-height:108%; font-family:'Arial Nova'; font-size:13pt }
        h1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 8pt }
        li { margin-top:0pt; margin-bottom:8pt }
        h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:22pt; font-weight:normal; color:#2e74b5 }
        h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#2e74b5 }
        h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; color:#2e74b5 }
        h6 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#595959 }
        .Heading7 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:normal; color:#595959; -aw-style-name:heading7 }
        .Heading8 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:italic; color:#272727; -aw-style-name:heading8 }
        .Heading9 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:13pt; font-weight:normal; font-style:normal; color:#272727; -aw-style-name:heading9 }
        .IntenseQuote { margin:18pt 43.2pt; text-align:center; line-height:108%; border-top:0.75pt solid #2e74b5; border-bottom:0.75pt solid #2e74b5; padding-top:10pt; padding-bottom:10pt; font-size:13pt; font-style:italic; color:#2e74b5; -aw-border-bottom:0.5pt single; -aw-border-top:0.5pt single; -aw-style-name:intense-quote }
        .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:13pt; -aw-style-name:list-paragraph }.Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:13pt; font-style:italic; color:#404040; -aw-style-name:quote }
        .Subtitle { margin-bottom:8pt; line-height:108%; font-family:Aptos; font-size:22pt; letter-spacing:0.75pt; color:#595959; -aw-style-name:subtitle }
        .Title { margin-bottom:4pt; line-height:normal; font-family:'Aptos Display'; font-size:30pt; letter-spacing:-0.5pt; -aw-style-name:title }
        span.CitaCar { font-style:italic; color:#404040 }
        span.CitadestacadaCar { font-style:italic; color:#2e74b5 }
        span.IntenseEmphasis { font-style:italic; color:#2e74b5; -aw-style-name:intense-emphasis }
        span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#2e74b5; -aw-style-name:intense-reference }
        span.SubttuloCar { font-family:Aptos; font-size:22pt; letter-spacing:0.75pt; color:#595959 }
        span.Ttulo1Car { font-family:'Aptos Display'; font-size:22pt; color:#2e74b5 }
        span.Ttulo2Car { font-family:'Aptos Display'; font-size:22pt; color:#2e74b5 }
        span.Ttulo3Car { font-family:Aptos; font-size:22pt; color:#2e74b5 }
        span.Ttulo4Car { font-family:Aptos; font-style:italic; color:#2e74b5 }
        span.Ttulo5Car { font-family:Aptos; color:#2e74b5 }
        span.Ttulo6Car { font-family:Aptos; font-style:italic; color:#595959 }
        span.Ttulo7Car { font-family:Aptos; color:#595959 }
        span.Ttulo8Car { font-family:Aptos; font-style:italic; color:#272727 }
        span.Ttulo9Car { font-family:Aptos; color:#272727 }
        span.TtuloCar { font-family:'Aptos Display'; font-size:30pt; letter-spacing:-0.5pt }
        .Tablaconcuadrcula3 {  }.TableGrid {  }
      </style>
    </head>
    <body>
      <div>
        <br>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">La Metodologia OW è un'esperienza esperienziale, che richiede una partecipazione rigorosa ad ogni giornata di lavoro di gruppo.</span>
        </p>
        <p style="margin-bottom:12pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">La FREQUENZA MINIMA per ottenere risultati e considerare il Programma OW svolto è pari al 90% delle sessioni di gruppo.</span>
        </p>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">OW, nel rispetto dei propri standard di Qualità e Impegni Etici, adempirà rigorosamente all'impegno di non fare ciò che non è utile per i partecipanti o che pregiudica i risultati attesi. </span>
        </p>
        <p style="margin-bottom:12pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">Se per cause di forza maggiore (malattia, incidente grave, ecc.) qualche partecipante non può frequentare il minimo richiesto, è sempre a discrezione del Consulente Top-senior OW che dirige il Ciclo, a seconda del grado di coinvolgimento, della qualità del lavoro eseguite, il rispetto degli obiettivi prefissati e l'impegno nel processo di sviluppo del partecipante, determinano le possibili opzioni da applicare.</span>
        </p>
        <p style="margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="text-decoration:underline; color:#333333">Le alternative valide per proseguire lo svolgimento del Programma sono le seguenti:</span>
        </p>
        <p style="margin-left:7.1pt; margin-bottom:0pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:22pt">
            <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:13pt; color:#0070c0"> </span>
            <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">1.- Opzioni che mantengono il livello di dinamica e risultati del Ciclo OW:</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
            <span class="asistencia-span" style="-aw-import:ignore">
            <span class="asistencia-span" style="font-weight:bold; color:#262626">
            <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
            <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
            <span class="asistencia-span" style="color:#333333">L'opzione ottimale: partecipare lo stesso giorno in un altro gruppo in anticipo, preferibilmente in un altro gruppo della stessa azienda che sta portando avanti lo stesso programma OW in parallelo, o in un gruppo aperto che abbia disponibilità e una sequenza di date compatibile.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
            <span class="asistencia-span" style="-aw-import:ignore">
            <span class="asistencia-span" style="font-weight:bold; color:#262626">
            <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
            <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
            <span class="asistencia-span" style="color:#333333">Esegui o completa il Ciclo OW più tardi in un gruppo futuro, sia della stessa azienda che apertamente, quando puoi garantire la piena partecipazione.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:6pt; text-indent:-18pt; text-align:justify; line-height:normal; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
            <span class="asistencia-span" style="-aw-import:ignore">
            <span class="asistencia-span" style="font-weight:bold; color:#262626">
            <span class="asistencia-span" style="font-family:'Wingdings 3'; font-weight:normal; color:#ff9933"></span>
        </span>
            <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
        </span>
            <span class="asistencia-span" style="color:#333333">Nel caso in cui siano previste più assenze per un giorno, si propone di riprogrammare la data di quel giorno per l'intero gruppo ad una nuova data, che potrebbe essere anche la data del giorno successivo previsto e aggiungere una nuova data successiva per il ultima sessione.</span>
            <span class="asistencia-span" style="color:#ff0000"> </span>
            <span class="asistencia-span" style="color:#333333">Tale opzione è condizionata alla disponibilità dell'agenda del Direttore del Ciclo e il nuovo calendario dovrà essere concordato con l'area pianificazione OW. Se l'avviso di posticipo avviene meno di una settimana prima della data prevista, la cancellazione di quel giorno avrà un costo di €760</span>
        </p>
        <p style="margin-left:28.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:22pt">
            <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; font-size:22pt; letter-spacing:-0.15pt; color:#0070c0">2.- Opzioni limitate:</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">Queste alternative ci permettono di vedere ciò che è stato discusso durante la giornata con una significativa perdita di esperienza, impatto e contenuto, con quel tanto che basta per poter riprendere il ciclo. </span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">Per questo, a </span>
            <span class="asistencia-span" style="font-weight:bold; color:#333333">Sessione di impegno ridotto (S.E.R). </span>
            <span class="asistencia-span" style="color:#333333">Con questa sessione ottieni solo il minimo per poter “agganciare” il gruppo e proseguire. Queste opzioni si possono fare UNA SOLA VOLTA, quindi, se ne fai una, significa che, se salti un qualsiasi altro giorno, totalmente o parzialmente, significa che verrai escluso dal Ciclo perché la sequenza esperienziale è interrotta e la trasformazione processo.</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:6pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="font-weight:bold; color:#333333">El Precio por una S.E.R. es de 430.-€ y se concretará en función de agendas de consultores OW.</span>
        </p>
        <p style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:6pt">
            <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:25.1pt; margin-bottom:6pt; text-align:justify; line-height:normal; font-size:12pt">
            <span class="asistencia-span" style="font-family:'Arial Nova Cond Light'; letter-spacing:-0.15pt; color:#0070c0">OPZIONI:</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
            <span class="asistencia-span" style="-aw-import:ignore">
            <span class="asistencia-span" style="color:#333333">
            <span class="asistencia-span" style="font-family:'Wingdings 3'; font-size:13pt; color:#ff9933"></span>
            </span>
            <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            </span>
            <span class="asistencia-span" style="color:#0070c0">Esperienze con il gruppo + S.E.R.</span>
            <span class="asistencia-span" style="font-weight:bold; color:#0070c0"> </span>
            <span class="asistencia-span" style="color:#404040">(dal 2° giorno)</span>
        </p>
        <p style="margin-left:67.65pt; margin-bottom:6pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="font-weight:bold; color:#333333">Partecipa, di persona o virtualmente, alla prima ora e mezza della giornata</span>
            <span class="asistencia-span" style="color:#333333"> ascoltare, condividere e contribuire con esperienze sui risultati ottenuti dall'implementazione degli obiettivi fissati il ​​giorno precedente. Successivamente verrà programmato un S.E.R. della durata minima di un'ora e mezza in cui vedere il minimo sufficiente che il resto del gruppo ha lavorato in modalità esperienziale di gruppo per poter continuare a svolgere il programma il giorno successivo.</span>
        </p>
        <p style="margin-left:54pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:''; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:8.19pt">
            <span class="asistencia-span" style="-aw-import:ignore">
            <span class="asistencia-span" style="color:#333333">
            <span class="asistencia-span" style="font-family:'Wingdings 3'; font-size:13pt; color:#ff9933"></span>
            </span>
            <span class="asistencia-span" style="width:8.19pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            </span>
            <span class="asistencia-span" style="color:#0070c0">Solo il S.E.R.</span>
            <span class="asistencia-span" style="color:#333333">(Alternativa minima). </span>
        </p>
        <p style="margin-left:67.65pt; margin-bottom:0pt; text-align:justify; line-height:normal">
            <span class="asistencia-span" style="color:#333333">Nel caso in cui le alternative precedenti risultino impossibili, il minimo per poter proseguire è effettuare, in modalità Live-Virtual o di persona, un </span>
            <span class="asistencia-span" style="font-weight:bold; color:#333333">S.E.R. con il consulente OW di alto livello</span>
            <span class="asistencia-span" style="color:#333333">, della durata di un'ora e mezza, per vedere quanto permette di continuare a svolgere il programma di gruppo e di rilanciarsi nelle dinamiche esperienziali il giorno successivo.</span>
        </p>
        <p style="margin-left:46.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-left:46.35pt; margin-bottom:0pt; text-align:justify; line-height:normal">
        <span class="asistencia-span" style="color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-right:8.65pt; margin-left:25.1pt; text-align:justify">
        <span class="asistencia-span" style="font-weight:bold; color:#333333; -aw-import:ignore">&#xa0;</span>
        </p>
        <p style="margin-right:8.65pt; margin-left:7.1pt; text-align:justify">
            <span class="asistencia-span" style="font-weight:bold; color:#333333">Processo decisionale per garantire la qualità:</span>
            <span class="asistencia-span" style="color:#333333"> I Direttori del Ciclo OW saranno quelli che determineranno come procedere con ciascuna persona e, in ogni caso, a seconda dello sviluppo del gruppo e del profilo del partecipante interessato, per aiutare a continuare a portare avanti il ​​programma di gruppo mantenendo il dinamiche e risultati del programma OW. I criteri del consulente OW saranno sempre rispettati per prendere la decisione e l'opzione migliore.</span>
        </p>
        <p style="margin-left:7.1pt; text-align:justify">
            <span class="asistencia-span" style="color:#333333">In ogni momento, in caso di imprevisti (assenze, cambiamenti di orari, ecc.), Otto Walter informerà e concorderà con l'azienda cliente le alternative applicabili e gli eventuali costi aggiuntivi che queste eventualmente comportano.</span>
        </p>
      </div>
    </body>
  </html>

    `;

export default ASSISTENZA_E_GUASTI;