import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'es',
    lng: sessionStorage.getItem('lang'),
    resources: {
        en: {
            translations: require('./locales/en/translations.json'),
        },
        es: {
            translations: require('./locales/es/translations.json'),
        },
        it: {
            translations: require('./locales/it/translations.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

export default i18n;