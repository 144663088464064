import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        outletZoneConsultores: {
            maxWidth: '700px',
            margin: '0 auto',
            maxHeight: 'calc(100vh - 100px)',
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            padding: '25px 15px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0 auto',
                maxHeight: 'calc(100vh - 100px)',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                padding: '0px',
            },
        },
        card: {
            maxWidth: '450px',
            background: 'white',
            marginBottom: '0.5em',
            border: '1px solid lightgrey',
            padding: '0.5em 0.5em 0.5em 0.8em'
        },
        cardSlim: {
            minWidth: '250px',
            width: '100%',
            background: 'white',
            marginBottom: '0.3em',
            border: '1px solid lightgrey',
            padding: '0.4em 0.5em 0.2em 0.5em'
        },
        cardInverse: {
            minWidth: '250px',
            width: '100%',
            backgroundColor: theme.palette.primary.main ,
            marginBottom: '0.5em',
            border: '1px solid lightgrey',
            padding: '0.5em 0.5em 0.5em 0.8em'
        },
        padding0: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        },
        title: {
            marginTop: '15px',
            marginBottom: '30px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle: {
            margin: '10px 5px 10px 20px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle2: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1em',
            textAlign: 'left',
            marginLeft: '0.4em'
        },
        subtitle2I: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1em',
            textAlign: 'left',
            marginLeft: '0.4em'
        },
        image: {
            width: '45px',
            // [theme.breakpoints.down('sm')]: {
            //     width: '25%',
            // },
        },
        colorPrimary: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main
        },
        btnSecondary: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.1em'
        },
        lineHeight: {
            lineHeight: '2em',
            [theme.breakpoints.down('sm')]: {
                lineHeight: '1em'
            },
        },
        noPointer: {
          cursor: 'default !important', // Forzar el cursor a default
      }
        
    };
});